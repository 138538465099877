import React,{useState}from "react";
import { PictureAsPdf, Image, Folder } from "@mui/icons-material";
import Box from "@mui/material/Box";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import apiService from "../../../Shared/Apiserver";
const Item = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
const File = ({ tsb_ref,id }) => {
  const [filedata, setfileData] = React.useState([]);
  React.useEffect(() => {
    getFile();
  }, []);
  const getFile = async () => {
    try {
      const data = {
        data: tsb_ref,
      };
      const getFile = await apiService.getFile(data);
      console.log(getFile);
      setfileData(getFile.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const downloadFile = async (item) => {
    try {
      console.log(item);
      const response = await axios.post(
        "https://mcon-oil.mconcrete.co.th/api_lawyer/download/file",
        {
          fileName: item, // ส่งชื่อไฟล์ไปกับคำขอ
        },
        {
          responseType: "blob", // ให้เป็น blob เพื่อดาวน์โหลดไฟล์
        }
      );

      // สร้าง URL สำหรับไฟล์ที่ดาวน์โหลด
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item); // ตั้งชื่อไฟล์ที่ดาวน์โหลด
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };
  const getFileType = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    if (extension === "pdf") {
      return "pdf";
    } else if (extension === "jpg" || extension === "png") {
      return "pic";
    } else {
      return "folder";
    }
  };
  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    setFile(event.target.files[0]);
  };
  const generateNewFileName = (originalFileName) => {
    const date = dayjs(new Date()).format('hhmm')
    const fileExtension = originalFileName.split('.').pop(); // ดึงนามสกุลของไฟล์
    const uniqueSuffix = `folder==${tsb_ref}__${date}.${fileExtension}`; // สร้างส่วน suffix ที่ไม่ซ้ำกัน
    return `${uniqueSuffix}`; // สร้างชื่อไฟล์ใหม่
  };
  const handleSubmit = async (event) => {
    if (file) {
      console.log("Uploading file...");
      event.preventDefault();

      const formData = new FormData();
      const newFileName = generateNewFileName(file.name); // สร้างชื่อไฟล์ใหม่

      formData.append("image", file, newFileName);
      formData.append("id", id);

      try {
        const response = await apiService.uploadFile(formData);
      getFile(tsb_ref)
      } catch (error) {
        console.error("Error uploading file", error);
      }
    }
  };
  return (
    <Grid item container>
      <Modal
        open={open4}
        onClose={handleClose4}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="fileInput"
            />
            <label htmlFor="fileInput">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                component="span"
              >
                อัพโหลดเอกสาร
              </Button>
            </label>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="file"
              name="file"
              label="File"
              value={file ? file.name : ""}
              disabled
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              Upload
            </Button>
          </form>
        </Box>
      </Modal>
      <Grid xs={12} md={4} xl={4}>
        <Item>
          <Button
            fullWidth
            onClick={handleOpen4}
            variant="contained"
            color="primary"
          >
            อัพโหลดเอกสาร
          </Button>
        </Item>
      </Grid>
      <Grid xs={12} md={12} xl={12}>
        {filedata.map((item, index) => (
          <Stack>
            <Icon>
              {getFileType(item) === "pdf" && <PictureAsPdf />}
              {getFileType(item) === "pic" && <Image />}
              {getFileType(item) === "folder" && <Folder />}
            </Icon>
            <Typography variant="body5">
              {item.split("uploads/")[1]}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() => downloadFile(item.split("uploads/")[1])}
            >
              Download
            </Button>
          </Stack>
        ))}
      </Grid>
    </Grid>
  );
};

export default File;
